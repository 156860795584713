import LazyLoad from 'react-lazyload';
import { getData } from '../db/wishes';
import '../styles/chatterbox.scss';

const bDayWishes=  await getData()

const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/ayushi-bday.appspot.com/o/wishes%2F{media_name}?alt=media'

export const ChatterBox = () => {
  
  return (
    <div className="chatter-box">
      {bDayWishes.map((message, index) => (
        <div class="col-lg-4 col-sm-12 col-md-6 message-wrapper" key={index}>
          {message.photo && (
            <div className='sender-image'>
               <LazyLoad once="true">
                  <img src={baseUrl.replace(/{media_name}/, message.photo)} alt="sender-image" />
                </LazyLoad>
            </div>
          )}
          <div className={"message" + (message.photo ? ' sm': '')}>{message.message}</div>
          <div className='d-flex'>- <div className="sender">{message.sender}</div></div>
        </div>
      ))}
    </div>
  )
}