export const DDay = new Date(Date.UTC(2024, 8, 20, 18, 30))

export const bDayWishes = [
  {
    sender: 'Nikita (Moni)',
    message: 'BDay wish 1'
  },
  {
    sender: 'Varun',
    message: 'BDay wish 2'
  }
]