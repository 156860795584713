import { useCallback, useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import Lightbox from "yet-another-react-lightbox";
import LazyLoad from "react-lazyload";

import { getData } from "../db/timeline";

import "yet-another-react-lightbox/styles.css";
import '../styles/timeline.scss';

 
// a component which renders a timeline of events for a given user over multiple years
const timelinesDB = await getData();
const timelines = (timelinesDB ?? []).sort((a, b) => Number(b.date) - Number(a.date));


const base_url = 'https://firebasestorage.googleapis.com/v0/b/ayushi-bday.appspot.com/o/timeline%2F{media_item}?alt=media'

const getImgSrc = (media_name) => {
  return base_url.replace('{media_item}', media_name);
}

export const Timeline = () => {

  const [currentTimeline, setCurrentTimeline] = useState(null);

  const togglePreviews = useCallback((index, show = true) => {
    if(show) {
      setCurrentTimeline(timelines[index]);
    }
    else {
      setCurrentTimeline(null);
    }
  }, [setCurrentTimeline]);



  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  
  const renderImages = useCallback((images) => {
    if(Array.isArray(images) && images.length > 1) {
      return images.slice(0, 2).map((image, index) => {
        
        return (
          
            <img className='timeline-image' src={getImgSrc(image)} alt={`timeline-${index}`} key={index} />
        )
      })
    }
    else if(Array.isArray(images) && images.length === 1) {
      return (
          <img className='timeline-image' src={getImgSrc(images[0])} alt='timeline' />
      )
    }
  }, []);

  const renderPhotoSlider = useCallback((timeline) => {
    if(!timeline) return null;
    
    const images = timeline.images.map((image, i) => ({ src: getImgSrc(image), key: `${timeline.date}-image-${i}` }));
    
    return (
      <Lightbox
        open={true}
        close={() => togglePreviews(0, false)}
        slides={images}
      />
      // <PhotoSlider
      //   images={images}
      //   visible={true}
      //   onClose={() => togglePreviews(0, false)}
      //   index={0}
      //   // onIndexChange={() => {}}
      // />

      // <PhotoProvider>
      //   {images.map((item, index) => (
      //     <PhotoView key={index} src={item}>
      //       <img src={item} alt="" />
      //     </PhotoView>
      //   ))}
      // </PhotoProvider>
    )
  }, [togglePreviews]);

  const renderTimeline = useCallback(() => {
    return timelines.map((timeline, index) => {
      console.log('renderTimeline', timeline, index);
      
      return (
        <div className='timeline__event' key={`${timeline.date}-event-${index}`}>
          {(index % 2 === 0 || isSmallDevice) && (
            <div className='timeline__event__date left'>{timeline.date}<div className="circle"></div></div>
          )}
          <div className='timeline__event__description'>
            <div className='timeline-image__wrapper' onClick={() => togglePreviews(index, true)}><LazyLoad once="true">{renderImages(timeline.images)}</LazyLoad></div>
          </div>
          {index % 2 !== 0 && !isSmallDevice && (
            <div className='timeline__event__date right'>{timeline.date}<div className="circle"></div></div>
          )}
        </div>
      )
    })
  }, [isSmallDevice, renderImages, togglePreviews]);
  return (
    <div className='timeline'>
      {renderTimeline()}
      {renderPhotoSlider(currentTimeline)}
    </div>
  );
}