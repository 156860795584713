import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyC0NIFCTXtQIkQteN_p0LUn8PDQNZdWB3Y",
  authDomain: "ayushi-bday.firebaseapp.com",
  databaseURL: "https://ayushi-bday-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ayushi-bday",
  storageBucket: "ayushi-bday.appspot.com",
  messagingSenderId: "57132516027",
  appId: "1:57132516027:web:c3470743693bbd3d6e9f9e",
  measurementId: "G-H5RDJY4CJQ"
};

const app = initializeApp(firebaseConfig);

export async function getData() {
  const db = getFirestore(app);
  const snapshot = await getDocs(collection(db, "timeline"));
  const docs = [];
  snapshot.forEach((doc) => docs.push(doc.data()));
  return docs;
}