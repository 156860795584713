import './App.scss';
import { CountDown } from './components/countdown';
import { Timeline } from './components/timeline';
import { ChatterBox } from './components/chatterbox';
import { useMediaQuery } from '@uidotdev/usehooks';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function App() {

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const hero_video_name = isSmallDevice ? 'hero_video_small' : 'hero_video';
  return (
    <div className="App">
      <header id="header" className="header bg-body-tertiary fixed-top">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href='#home'>Ayushi's B'day</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav id="navmenu" className="me-auto">
                <Nav.Link href="index.html#home" className="active">Home</Nav.Link>
                <Nav.Link href="index.html#timeline">Over the years</Nav.Link>
                <Nav.Link href="index.html#chatter">Shout-outs</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <main className="main">

        <section id="home" className="hero section">

          <video autoPlay muted loop id="myVideo">
            <source src={`https://firebasestorage.googleapis.com/v0/b/ayushi-bday.appspot.com/o/${hero_video_name}.mp4?alt=media`} type="video/mp4" />
          </video>

          <div className="container">
            <div className="row">
              <div className="hero-header col-lg-10">
                <div className='hero-header__title'>Celebrations 🎂</div>
                <CountDown />
              </div>
              </div>
            </div>

        </section>
        <section id="timeline" className="features section">

          {/* Section Title  */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Over the years</h2>
            
          </div>

          <div className="container">
            <div className="row gy-4 align-items-center features-item">
              <Timeline />

            </div>
          </div>

        </section>{/* /Features Section  */}


        {/* Testimonials Section  */}
        <section id="chatter" className="testimonials section light-background">
        <div className="container section-title" data-aos="fade-up">
            <h2>Birthday Shout-Outs</h2>
            
          </div>

          <div className="container">

            <div className="row align-items-center" style={{justifyContent: 'center'}}>
                <ChatterBox />
            </div>
          </div>
        </section>
      </main>


      {/* <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
    </div>
  );
}

export default App;
